import React from 'react';
import Button from '../../../ButtonNew';
import styles from './styles/ButtonContact.module.scss';
import Hidden from '@material-ui/core/Hidden';

const ButtonContact = ({ onClick }) => (
  <div className={styles.root} onClick={onClick}>
    <Button className={styles.button} variant="white">
      <Hidden lgDown>Contact Us for a demonstration</Hidden>
      <Hidden xlUp>Get a demo</Hidden>
    </Button>
  </div>
);

export default ButtonContact;
