import React from 'react';
import styles from './styles/Title.module.scss';

const Title = () => (
  <div className={styles.root}>
    <b>Digital Authentication & Storytelling for Art</b>
    <br />A Service for Artists & Art Sellers
  </div>
);

export default Title;
