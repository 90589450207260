import React from 'react';
import styles from './styles/Features.module.scss';
import { ReactComponent as FeatureIcon1 } from './assets/images/a.svg';
import { ReactComponent as FeatureIcon2 } from './assets/images/b.svg';
import { ReactComponent as FeatureIcon3 } from './assets/images/c.svg';
import Feature from './components/Feature';

const Features = () => (
  <div className={styles.root}>
    <Feature icon={FeatureIcon1}>Verify authenticity</Feature>
    <Feature icon={FeatureIcon2}>Explore rich content</Feature>
    <Feature icon={FeatureIcon3}>Boost sales</Feature>
  </div>
);

export default Features;
