import React from 'react';
import styles from './styles/Description.module.scss';

const Description = () => (
  <div className={styles.root}>
    Our proprietary LuxVerity seals contain tamper-resistant,
    cryptographically-secure NFC microchips. Discreetly attached to authentic
    artwork, the seals can be read by any modern smart phone. When the smart
    phone communicates with the LuxVerity seal, a secure gateway is created
    which verifies the work’s authenticity and provides a{' '}
    <b>
      Digital Docent<sup>®</sup>
    </b>{' '}
    experience with rich media content.
    <br />
    <br />
    LuxVerity also offers a Digital Docent-only experience that can be launched
    from a standalone interactive gallery tag.
  </div>
);

export default Description;
