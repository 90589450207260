import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ContactPopup from '../ContactPopup';
import styles from './styles/Footer.module.scss';
import classNames from 'classnames';

const Footer = () => {
  const [contactFormVisible, setContactFormVisible] = useState(false);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.name}>
          &copy; {new Date().getFullYear()} LuxVerity, LLC. All rights reserved.
        </div>
        <div className={styles.container}>
          <RouterLink
            to={{ pathname: '/terms.pdf' }}
            className={styles.link}
            target="_blank"
          >
            Terms of Service
          </RouterLink>
          <RouterLink
            to={{ pathname: '/privacy.pdf' }}
            className={styles.link}
            target="_blank"
          >
            Privacy Policy
          </RouterLink>
          <div
            className={classNames(styles.link, styles.contact)}
            onClick={() => setContactFormVisible(true)}
          >
            Contact Us
          </div>
        </div>
      </div>
      {contactFormVisible && (
        <ContactPopup onClose={() => setContactFormVisible(false)} />
      )}
    </>
  );
};

export default Footer;
