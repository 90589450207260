import React from 'react';
import styles from './styles/ButtonContact.module.scss';
import Button from '../../../../../../components/ButtonNew';

const ButtonContact = ({ onClick }) => (
  <div className={styles.root} onClick={onClick}>
    <Button className={styles.button} variant="blue">
      Contact Us for a demonstration
    </Button>
  </div>
);

export default ButtonContact;
