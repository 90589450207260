const buttonStyles = (theme) => ({
  root: {
    display: 'inline-block',
    padding: theme.spacing(2, 5),
    background: theme.palette.g.g700,
    borderRadius: 4,
    border: 0,
    cursor: 'pointer',
    transition: 'box-shadow 0.3s ease-in-out',
  },
  variantPrimary: {
    background: theme.palette.g.g700,
    '& $text': {
      color: theme.palette.ink.ink0,
    },
    '&:hover': {
      boxShadow:
        '0px 20.5083px 32.4715px rgba(29, 17, 51, 0.04), 0px 8.54512px 41.0166px rgba(9, 32, 77, 0.12), 0px 10.2541px 13.6722px rgba(29, 17, 51, 0.12)',
    },
    '&:active': {
      background: theme.palette.g.g800,
    },
    '&$disabled': {
      background: theme.palette.ink.ink40,
      boxShadow: 'unset',
      cursor: 'not-allowed',
    },
  },
  variantSecondary: {
    background: theme.palette.ink.ink0,
    '& $text': {
      color: theme.palette.ink.ink90,
    },
    '&:hover': {
      boxShadow:
        '0px 20.5083px 32.4715px rgba(29, 17, 51, 0.04), 0px 8.54512px 41.0166px rgba(9, 32, 77, 0.12), 0px 10.2541px 13.6722px rgba(29, 17, 51, 0.12)',
    },
    '&:active': {
      background: theme.palette.red.red10,
    },
    '&$disabled': {
      background: theme.palette.ink.ink20,
      boxShadow: 'unset',
      cursor: 'not-allowed',
    },
  },
  variantBlack: {
    background: theme.palette.ink.ink80,
    '& $text': {
      color: theme.palette.ink.ink0,
    },
    '&:hover': {
      boxShadow:
        '0px 20.5083px 32.4715px rgba(29, 17, 51, 0.04), 0px 8.54512px 41.0166px rgba(9, 32, 77, 0.12), 0px 10.2541px 13.6722px rgba(29, 17, 51, 0.12)',
    },
    '&:active': {
      background: theme.palette.red.red80,
    },
    '&$disabled': {
      background: theme.palette.ink.ink40,
      boxShadow: 'unset',
      cursor: 'not-allowed',
    },
  },
  variantWhite: {
    background: '#FAFAFA',
    '& $text': {
      color: theme.palette.blue.b900,
    },
    '&:hover': {
      boxShadow:
        '0px 20.5083px 32.4715px rgba(29, 17, 51, 0.04), 0px 8.54512px 41.0166px rgba(9, 32, 77, 0.12), 0px 10.2541px 13.6722px rgba(29, 17, 51, 0.12)',
    },
    '&:active': {
      background: theme.palette.ink.ink10,
    },
    '&$disabled': {
      background: theme.palette.ink.ink40,
      boxShadow: 'unset',
      cursor: 'not-allowed',
    },
  },
  variantBlue: {
    background: theme.palette.blue.b600,
    '& $text': {
      color: theme.palette.ink.ink0,
    },
    '&:hover': {
      boxShadow:
        '0px 20.5083px 32.4715px rgba(29, 17, 51, 0.04), 0px 8.54512px 41.0166px rgba(9, 32, 77, 0.12), 0px 10.2541px 13.6722px rgba(29, 17, 51, 0.12)',
    },
    '&:active': {
      background: theme.palette.blue.b700,
    },
    '&$disabled': {
      background: theme.palette.ink.ink40,
      boxShadow: 'unset',
      cursor: 'not-allowed',
    },
  },
  text: {},
  disabled: {},
});

export default buttonStyles;
