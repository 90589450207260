import React from 'react';
import styles from './styles/Seal.module.scss';
import { ReactComponent as SealIcon } from './assets/images/seal.svg';

const Seal = () => (
  <div className={styles.root}>
    <SealIcon className={styles.image} />
  </div>
);

export default Seal;
