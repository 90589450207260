import React from 'react';
import Title from './components/Title';
import styles from './styles/About1.module.scss';
import Features from './components/Features';
import Description from './components/Description';

const About1 = () => (
  <div className={styles.root}>
    <Title />
    <Features />
    <Description />
  </div>
);

export default About1;
