import React, { useState } from 'react';
import styles from './styles/About2.module.scss';
import Seal from './components/Seal';
import Title from './components/Title';
import Description from './components/Description';
import ButtonContact from './components/ButtonContact';
import ContactPopup from '../../../../components/ContactPopup';

const About2 = () => {
  const [contactFormVisible, setContactFormVisible] = useState(false);

  return (
    <>
      <div className={styles.root}>
        <Seal />
        <Title />
        <Description />
        <ButtonContact onClick={() => setContactFormVisible(true)} />
      </div>
      {contactFormVisible && (
        <ContactPopup onClose={() => setContactFormVisible(false)} />
      )}
    </>
  );
};

export default About2;
