import React from 'react';
import styles from './styles/Feature.module.scss';

const Feature = ({
  title,
  image: Image,
  icon: Icon,
  children,
  variant = 'normal',
}) => {
  if (variant === 'normal') {
    return (
      <div className={styles.root}>
        <div className={styles.imageContainer}>
          <Image className={styles.image} />
        </div>
        <div className={styles.content}>
          <Icon className={styles.icon} />
          <div className={styles.title}>{title}</div>
          <div className={styles.line} />
          <div className={styles.description}>{children}</div>
        </div>
      </div>
    );
  }
  if (variant === 'reserved') {
    return (
      <div className={styles.root}>
        <div className={styles.content}>
          <Icon className={styles.icon} />
          <div className={styles.title}>{title}</div>
          <div className={styles.line} />
          <div className={styles.description}>{children}</div>
        </div>
        <div className={styles.imageContainer}>
          <Image className={styles.image} />
        </div>
      </div>
    );
  }
};

export default Feature;
