import React from 'react';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import fieldStyles from './styles/fieldStyles';

class Field extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      error: null,
      touched: false,
    };
  }

  setValue = (value) => this.setState({ value });

  setError = (error) => this.setState({ error });

  setTouched = (touched) => this.setState({ touched });

  validate = (value) => {
    const { validate } = this.props;
    const { touched } = this.state;

    if (!touched) {
      this.setTouched(true);
    }

    if (validate) {
      const error = validate(value);
      this.setError(error);

      return error;
    } else {
      this.setError(null);

      return null;
    }
  };

  onChange = (event) => {
    const { touched } = this.state;

    if (touched) {
      this.validate(event);
    }

    this.setValue(event.target.value);
  };

  get value() {
    return this.state.value;
  }

  get error() {
    return this.state.error;
  }

  render() {
    const { classes, disabled, name, label, validate, ...props } = this.props;
    const { value, error, touched } = this.state;

    return (
      <TextField
        className={classes.root}
        name={name}
        value={value}
        onChange={this.onChange}
        onBlur={(event) => this.validate(event.target.value)}
        label={label}
        variant="outlined"
        fullWidth
        error={!!error && touched}
        helperText={error || ' '}
        disabled={disabled}
        {...props}
      />
    );
  }
}

export default withStyles(fieldStyles)(Field);
