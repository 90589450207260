import React from 'react';
import Contact from './components/Contact';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Banner from './components/Banner';
import About1 from './components/About1';
import About2 from './components/About2';
import About3 from './components/About3';

const Home = () => (
  <>
    <Header />
    <Banner />
    <About1 />
    <About2 />
    <About3 />
    <Contact />
    <Footer />
  </>
);

export default Home;
