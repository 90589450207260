import React from 'react';
import styles from './styles/Feature.module.scss';

const Feature = ({ children, icon: Icon }) => (
  <div className={styles.root}>
    <Icon className={styles.icon} />
    <div className={styles.text}>{children}</div>
  </div>
);

export default Feature;
