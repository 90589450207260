import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import capitalize from '../../utils/capitalize';
import styles from './styles/Button.module.scss';

const Button = ({
  classes,
  children,
  className,
  onClick,
  variant = 'white',
  disabled,
  ...props
}) => (
  <button
    className={classNames(styles.root, {
      [styles[`variant${capitalize(variant)}`]]: variant,
    })}
    onClick={disabled ? () => {} : onClick}
    {...props}
  >
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['white', 'blue']),
  onClick: PropTypes.func,
};

export default Button;
