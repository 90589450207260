import React from 'react';
import styles from './styles/Description.module.scss';

const Description = () => (
  <div className={styles.root}>
    When unlocked, the LuxVerity system generates a unique digital certificate
    of authenticity in real-time, with every interaction, thus helping clients
    combat counterfeiting. In addition, the gateway launches rich digital
    content about the item, that can include a detailed description of the
    piece, the biography of the artist, and video of the piece’s inspiration.
  </div>
);

export default Description;
