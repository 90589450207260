import React, { useState } from 'react';
import Logo from './components/Logo';
import styles from './styles/Header.module.scss';
import ButtonContact from './components/ButtonContact';
import ContactPopup from '../ContactPopup';

const Header = () => {
  const [contactFormVisible, setContactFormVisible] = useState(false);

  return (
    <>
      <div className={styles.root}>
        <Logo />
        <ButtonContact onClick={() => setContactFormVisible(true)} />
      </div>
      {contactFormVisible && (
        <ContactPopup onClose={() => setContactFormVisible(false)} />
      )}
    </>
  );
};

export default Header;
