import React, { useState } from 'react';
import ContactPopup from '../../../../components/ContactPopup';
import styles from './styles/Contact.module.scss';
import Title from './components/Title';
import ButtonContact from './components/ButtonContact';

const Contact = () => {
  const [contactFormVisible, setContactFormVisible] = useState(false);

  return (
    <>
      <div className={styles.root}>
        <Title />
        <ButtonContact onClick={() => setContactFormVisible(true)} />
      </div>
      {contactFormVisible && (
        <ContactPopup onClose={() => setContactFormVisible(false)} />
      )}
    </>
  );
};

export default Contact;
