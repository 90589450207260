import React from 'react';
import styles from './styles/About3.module.scss';
import Feature from './components/Feature';
import { ReactComponent as FeatureIcon1 } from './assets/images/icon1.svg';
import { ReactComponent as FeatureIcon2 } from './assets/images/icon2.svg';
import { ReactComponent as FeatureIcon3 } from './assets/images/icon3.svg';
import { ReactComponent as FeatureImage1 } from './assets/images/feature1.svg';
import { ReactComponent as FeatureImage2 } from './assets/images/feature2.svg';
import { ReactComponent as FeatureImage3 } from './assets/images/feature3.svg';
import Hidden from '@material-ui/core/Hidden';

const About3 = () => (
  <div className={styles.root}>
    <Feature
      title={'Verify authenticity'}
      image={FeatureImage1}
      icon={FeatureIcon1}
    >
      Counterfeits comprise over 3% of global trade and annually exceed half a
      trillion dollars (oecd.org). To combat counterfeits, each LuxVerity seal
      contains three unique digital and physical identifiers that verify and
      maintain authenticity. Every time a LuxVerity seal is scanned with a
      cellphone, it dynamically generates and displays a unique Digital
      Certificate of Authenticity (DCOA), in real-time.
      <br />
      <br />
      The DCOA also establishes provenance for long-term ownership and maintains
      authenticity for heirloom transfers and resale.
    </Feature>
    <Hidden lgDown>
      <Feature
        title={'Explore rich content'}
        image={FeatureImage2}
        icon={FeatureIcon2}
        variant="reserved"
      >
        Launching the{' '}
        <b>
          Digital Docent<sup>®</sup>
        </b>{' '}
        enables art buyers the opportunity to make a personal connection with
        the the art piece and its creator. Descriptive details about the piece,
        such as a biography of the artist and a video of the inspiration,
        deliver immediate rich content that can be unlocked with a single scan,
        and this depth of storytelling travels with the piece wherever it goes.
        <br />
        <br />
        Additional LuxVerity capabilities include an interactive gallery tag,
        containing the LuxVerity proprietary seal that can be displayed next to
        an art piece- to launch the{' '}
        <b>
          Digital Docent<sup>®</sup>
        </b>{' '}
        experience.
      </Feature>
    </Hidden>
    <Hidden xlUp>
      <Feature
        title={'Explore rich content'}
        image={FeatureImage2}
        icon={FeatureIcon2}
      >
        Launching the Digital Docent<sup>®</sup> enables art buyers the
        opportunity to make a personal connection with the the art piece and its
        creator. Descriptive details about the piece, such as a biography of the
        artist and a video of the inspiration, deliver immediate rich content
        that can be unlocked with a single scan, and this depth of storytelling
        travels with the piece wherever it goes.
        <br />
        <br />
        Additional LuxVerity capabilities include an interactive gallery tag,
        containing the LuxVerity proprietary seal that can be displayed next to
        an art piece- to launch the Digital Docent<sup>®</sup> experience.
      </Feature>
    </Hidden>
    <Feature title={'Boost sales'} image={FeatureImage3} icon={FeatureIcon3}>
      Extensive consumer research shows that the LuxVerity platform, including
      authentication and the{' '}
      <b>
        Digital Docent<sup>®</sup>
      </b>{' '}
      experience, enhances the enjoyment and value of art, with art buyers
      willing to pay a premium for the service.
      <br />
      <br />
      Shopping and sharing capabilities expand marketing reach for art sellers
      and are easily customizable.
      <br />
      <br />
      Detailed reporting provides real-time marketing insights to drive
      incremental sales.
    </Feature>
  </div>
);

export default About3;
