import React from 'react';
import styles from './styles/Banner.module.scss';
import phoneImage from './assets/images/phone.png';

const Banner = () => (
  <div className={styles.root}>
    <img src={phoneImage} className={styles.image} alt="phone" />
  </div>
);

export default Banner;
