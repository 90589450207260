import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { ReactComponent as LogoImage } from './assets/images/logo.svg';
import styles from './styles/Logo.module.scss';

const Logo = () => (
  <div className={styles.root}>
    <LogoImage className={styles.image} onClick={() => scroll.scrollToTop()} />
    <div className={styles.text}>
      Authentication with Depth<sup>®</sup>
    </div>
  </div>
);

export default Logo;
