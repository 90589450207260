import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import Home from './pages/Home';
import ScrollToTop from './components/ScrollToTop';
import theme from './styles/theme';
import React from 'react';

const App = () => (
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </ThemeProvider>
  </BrowserRouter>
);

export default App;
