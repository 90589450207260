import React, { useRef, useState } from 'react';
import axios from 'axios';
import { ReactComponent as MailIcon } from './assets/images/mail.svg';
import Typography from '../Typography';
import Button from '../Button';
import Field from './components/Field';
import withStyles from '@material-ui/core/styles/withStyles';
import contactPopupStyles from './styles/contactPopupStyles';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: `/api/v1/`,
});

const validateEmail = (value) => {
  if (!value) {
    return 'Required';
  }
  if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)) {
    return 'Invalid email address';
  }

  return null;
};

const validateMessage = (value) => {
  if (!value) {
    return 'Required';
  }

  return null;
};

const Wrapper = ({ classes, children, onClose }) => (
  <div
    className={classes.root}
    onClick={(event) => {
      onClose();
    }}
  >
    <div
      className={classes.container}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {children}
      <div className={classes.close} onClick={onClose} />
    </div>
  </div>
);

const ContactPopup = ({ classes, onClose }) => {
  const formRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(null);

    const emailField = emailRef.current;
    const messageField = messageRef.current;

    if (!emailField || !messageField) {
      return;
    }

    const fields = [emailField, messageField];
    const invalid = fields.reduce((accumulator, field) => {
      const error = !!field.validate(field.value);
      return accumulator || error;
    }, false);

    if (invalid) {
      return;
    }

    setLoading(true);

    axiosInstance
      .post('contact-us/', {
        email: emailField.value,
        text: messageField.value,
      })
      .then(() => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setError('Something went wrong, try again later.');
        setLoading(false);
      });
  };

  if (success) {
    return (
      <Wrapper classes={classes} onClose={onClose}>
        <MailIcon className={classes.icon} />
        <Typography variant="h4" align="center" className={classes.title}>
          Your Message Has Been Sent!
        </Typography>
        <Typography align="center" className={classes.subTitle}>
          We will get back to you as soon as possible
        </Typography>
        <div className={classes.buttonContainer}>
          <Button className={classes.button} onClick={onClose} variant="blue">
            DONE
          </Button>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper classes={classes} onClose={onClose}>
      <Typography align="center" variant="h4" className={classes.title}>
        Contact Us
      </Typography>
      {error && (
        <Typography align="center" className={classes.error}>
          {error}
        </Typography>
      )}
      <form onSubmit={handleSubmit} method="POST" ref={formRef}>
        <div className={classes.fieldGroup}>
          <Field
            validate={validateEmail}
            name="email"
            label="Your email"
            disabled={loading}
            ref={emailRef}
          />
          <Field
            name="message"
            label="Your message"
            validate={validateMessage}
            multiline
            rows={4}
            disabled={loading}
            ref={messageRef}
          />
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            disabled={loading}
            component="button"
            type="submit"
            variant="blue"
          >
            SEND MESSAGE
          </Button>
        </div>
      </form>
    </Wrapper>
  );
};

export default withStyles(contactPopupStyles)(ContactPopup);
